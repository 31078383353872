import styled from 'styled-components';
import { colors, fonts } from 'styles/theme';
import flexGen from 'utils/flexGen';
import LightenDarkenColor from 'utils/lightenDarkenColor';
import toRem from 'utils/toRem';

interface IStyledSearchWrapper {
  width: string;
  marginTop: number;
  marginBottom: number;
  marginRight: number;
  marginLeft: number;
  borderRadiusTopLeft: string;
  borderRadiusTopRight: string;
  borderRadiusBottomLeft: string;
  borderRadiusBottomRight: string;
}

export const StyledSearchWrapper = styled.div<IStyledSearchWrapper>`
  ${flexGen('center', 'center')}
  width: ${(props) => props.width};
  background-color: ${colors.color2};
  height: 40px;
  border: 1px solid ${LightenDarkenColor(colors.color2, -25)};
  margin-top: ${(props) => toRem(props.marginTop)};
  margin-bottom: ${(props) => toRem(props.marginBottom)};
  margin-right: ${(props) => toRem(props.marginRight)};
  margin-left: ${(props) => toRem(props.marginLeft)};
  border-top-left-radius: ${(props) => props.borderRadiusTopLeft};
  border-top-right-radius: ${(props) => props.borderRadiusTopRight};
  border-bottom-left-radius: ${(props) => props.borderRadiusBottomLeft};
  border-bottom-right-radius: ${(props) => props.borderRadiusBottomRight};

  :focus {
    border: ${toRem(1)} solid ${colors.color1};
  }

  svg {
    fill: ${LightenDarkenColor(colors.color2, -196)};
  }
`;

export const StyledSearchInput = styled.input`
  border: none;
  caret-color: ${colors.color1};

  background-image: url('search.svg');
  background-repeat: no-repeat;
  background-position: 16px 8px;

  padding-left: ${toRem(48)};
  padding-top: ${toRem(10)};
  padding-bottom: ${toRem(10)};
  font-size: ${toRem(14)};
  width: 100%;
  font-family: ${fonts.font4};

  /* :focus {
    border: ${toRem(1)} solid ${colors.color1};
  } */
`;
