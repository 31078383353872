import React, { useEffect, useState } from 'react';
import Logo from 'components/Logo';
import SiteLink from 'components/SiteLink';
import { colors } from 'styles/theme';
import { fintelLinks } from 'components/Footer/links';
import { useLocation } from 'react-router-dom';
import ExternalLink from 'components/ExternalLink';
import { StyledNavigatorContainer, StyledNavigatorMenu } from './styles';

const Navigator: React.FC = () => {
  const [navChange, setNavChange] = useState(false);
  const [isRoot, setIsRoot] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 50) {
        setNavChange(true);
      } else {
        setNavChange(false);
      }
    });

    if (location.pathname === '/') {
      setIsRoot(true);
    } else {
      setIsRoot(false);
    }
  }, [location.pathname]);

  return (
    <StyledNavigatorContainer navChange={navChange} isRoot={isRoot}>
      <SiteLink to="/">
        <Logo size="small" backgroundColor={isRoot && !navChange ? 'black' : 'white'} />
      </SiteLink>
      <StyledNavigatorMenu>
        <ExternalLink
          color={isRoot && !navChange ? colors.color2 : colors.color3}
          href={fintelLinks.fintelConnect}
          title="Go to FintelConnect.com"
        />
        <ExternalLink
          color={isRoot && !navChange ? colors.color2 : colors.color3}
          href={process.env.REACT_APP_APP_URI!}
          title="Go to your dashboard"
        />
        <ExternalLink
          color={isRoot && !navChange ? colors.color2 : colors.color3}
          href={process.env.REACT_APP_MESSAGE_HUB!}
          title="Go to Messaging Hub"
        />
      </StyledNavigatorMenu>
    </StyledNavigatorContainer>
  );
};

export default Navigator;
