import styled from 'styled-components';
import toRem from 'utils/toRem';

interface IStyledSiteLink {
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
}

export const StyledSiteLink = styled.div<IStyledSiteLink>`
  margin-top: ${(props) => toRem(props.marginTop)};
  margin-bottom: ${(props) => toRem(props.marginBottom)};
  margin-right: ${(props) => toRem(props.marginRight)};
  margin-left: ${(props) => toRem(props.marginLeft)};

  a {
    text-decoration: none;
  }
  cursor: pointer;
`;
