import React from 'react';
import { useHistory } from 'react-router-dom';
import Text from 'components/Text';
import Button from 'components/Button';
import { colors, fonts } from 'styles/theme';
import { StyledURLNotFoundWrapper, StyledURLNotFoundContainer, StyledURLEmailLink } from './styles';

const URLNotFound: React.FC = () => {
  const history = useHistory();

  return (
    <StyledURLNotFoundWrapper>
      <StyledURLNotFoundContainer>
        <Text fontFamily={fonts.font2} fontSize={24} lineHeight={3.6}>
          Page not found
        </Text>
        <Text fontFamily={fonts.font1} lineHeight={1.4}>
          We&apos;re sorry, the page you requested could not be found. Please go back to the
          homepage or contact us at
          {' '}
          <Text color={colors.color1}>
            <StyledURLEmailLink href="mailto:info@fintelconnect.com">
              help@fintelconnect.com
            </StyledURLEmailLink>
          </Text>
        </Text>
        <Button
          onClick={() => {
            history.replace('/');
          }}
          marginTop={24}
        >
          Back to Homepage
        </Button>
      </StyledURLNotFoundContainer>
    </StyledURLNotFoundWrapper>
  );
};

export default URLNotFound;
