import React from 'react';
import Icon from 'components/Icon';
import Text from 'components/Text';

import { colors, fonts } from 'styles/theme';
import { StyledLogoContainer } from './styles';

interface LogoProps {
  size: string;
  backgroundColor: string;
}

const Logo: React.FC<LogoProps> = ({ size, backgroundColor }) => (
  <StyledLogoContainer>
    <Icon
      icon="logo"
      width={size === 'large' ? 43 : 25}
      height={size === 'large' ? 53 : 32}
      viewbox="0 0 25 32"
      marginBottom={size === 'large' ? 8 : 0}
    />
    <Text
      fontFamily={fonts.font3}
      color={backgroundColor === 'black' ? colors.color2 : colors.color3}
      fontSize={size === 'large' ? 24 : 16}
      letterSpace={4}
    >
      FINTEL
    </Text>
    <Text
      fontFamily={fonts.font3}
      color={backgroundColor === 'black' ? colors.color2 : colors.color3}
      fontSize={size === 'large' ? 12 : 8}
      letterSpace={2}
    >
      CONNECT
    </Text>
  </StyledLogoContainer>
);

export default Logo;
