import styled from 'styled-components';
import toRem from 'utils/toRem';

interface IStyledIconWrapper {
  marginTop: number;
  marginBottom: number;
  marginRight: number;
  marginLeft: number;
}

export const StyledIconWrapper = styled.div<IStyledIconWrapper>`
  margin-top: ${(props) => toRem(props.marginTop)};
  margin-bottom: ${(props) => toRem(props.marginBottom)};
  margin-right: ${(props) => toRem(props.marginRight)};
  margin-left: ${(props) => toRem(props.marginLeft)};
`;
