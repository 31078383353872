import React from 'react';
import Text from 'components/Text';
import Icon from 'components/Icon';

import { colors, fonts } from 'styles/theme';
import { StyledPaginationWrapper, StyledPaginationButton } from './styles';

interface PaginationProps {
  currentPage: number;
  maxPage: number;
  // eslint-disable-next-line no-unused-vars
  onChange(value: number): void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, maxPage, onChange }) => {
  const renderPages = () => [...Array(maxPage)].map((x, i) => (
    <StyledPaginationButton
        // eslint-disable-next-line react/no-array-index-key
      key={i}
      active={currentPage === i + 1}
      onClick={() => {
        onChange(i + 1);
      }}
    >
      <Text
        fontFamily={fonts.font2}
        color={currentPage === i + 1 ? colors.color2 : colors.color7}
      >
        {i + 1}
      </Text>
    </StyledPaginationButton>
  ));

  return (
    <StyledPaginationWrapper>
      <StyledPaginationButton
        active={false}
        onClick={() => {
          if (currentPage > 1) {
            onChange(currentPage - 1);
          }
        }}
      >
        <Icon icon="chev_left" width={20} height={20} viewbox="0 0 20 20" />
      </StyledPaginationButton>
      {renderPages()}
      <StyledPaginationButton
        active={false}
        onClick={() => {
          if (currentPage < maxPage) {
            onChange(currentPage + 1);
          }
        }}
      >
        <Icon icon="chev_right" width={20} height={20} viewbox="0 0 20 20" />
      </StyledPaginationButton>
    </StyledPaginationWrapper>
  );
};

export default Pagination;
