import styled from 'styled-components';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

export const StyledSearchWrapper = styled.div`
  ${flexGen('flex-start', 'center', 'column')};
  width: 100%;
  height: 100%;
  padding: ${toRem(32)} 8% ${toRem(64)} 8%;
`;

export const StyledSearchHeader = styled.div`
  ${flexGen('flex-start', 'flex-start', 'column')}
  width: 100%;
  padding-bottom: ${toRem(40)};
`;
