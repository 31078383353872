import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

interface IStyledPaginationButton {
  active: boolean;
}

export const StyledPaginationWrapper = styled.div`
  ${flexGen('space-between', 'center', 'row')};

  svg {
    fill: ${colors.color7};
  }

  button {
    cursor: pointer;
    border: none;
  }
`;

export const StyledPaginationButton = styled.button<IStyledPaginationButton>`
  ${flexGen('center', 'center', 'row')}
  padding: ${toRem(10)} ${toRem(16)} ${toRem(10)} ${toRem(16)};
  background-color: ${(props) => (props.active ? colors.color7 : colors.color2)};
  border-radius: ${toRem(6)};
`;
