import React from 'react';
import { Link } from 'react-router-dom';
import { StyledTextLinkWrapper, StyledTextLinkContainer } from './styles';

interface TextLinkProps {
  to: string;
  children: React.ReactNode;
  clickable?: boolean;
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
}

const TextLink: React.FC<TextLinkProps> = ({
  to,
  children,
  clickable = true,
  marginBottom = 0,
  marginTop = 0,
  marginLeft = 0,
  marginRight = 0,
}) => (
  <StyledTextLinkWrapper
    marginTop={marginTop}
    marginBottom={marginBottom}
    marginRight={marginRight}
    marginLeft={marginLeft}
    clickable={clickable}
  >
    <Link to={to}>
      <StyledTextLinkContainer>{children}</StyledTextLinkContainer>
    </Link>
  </StyledTextLinkWrapper>
);

TextLink.defaultProps = {
  clickable: true,
  marginBottom: 0,
  marginTop: 0,
  marginLeft: 0,
  marginRight: 0,
};

export default TextLink;
