import styled, { createGlobalStyle } from 'styled-components';
import flexGen from 'utils/flexGen';
import { fonts } from './theme';

export const AppContainer = styled.div`
  position: relative;
  ${flexGen('space-between', 'center', 'column')};
  width: 100vw;
  height: 100vh;
`;

const GlobalStyles = createGlobalStyle`
html, body, div, span, applet, object, iframe, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, tbody, tfoot, thead,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

strong {
  text-shadow: 0px 1px;
  letter-spacing: 0px;
}

* {
  box-sizing: border-box;


  /* scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  } */
}

:focus {
  outline: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  margin-left: 5%;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
border-collapse: collapse;
border-spacing: 100px;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden] {
    display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:focus {
    outline: thin dotted;
}

a:active,
a:hover {
  outline: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}
figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0; 
  padding: 0;
  white-space: normal; 
  *margin-left: -7px;
}

button,
input,
select,
textarea {
  font-size: 100%; 
  margin: 0; 
  vertical-align: baseline; 
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; 
  padding: 0; /* 2 */
  *height: 13px;
  *width: 13px;
}

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}

html,
button,
input,
select,
textarea {
  color: #222;
}


::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

table, tr, td, th {
  border: 1px solid #D6DADE;
  font-family: GothamBook;
  color: #253746;
}

th, td {
  text-align: left;
  vertical-align: middle;
  word-wrap: break-word;
}

th {
  text-shadow: 0px 1px;
  letter-spacing: 0px;
}

@font-face {
  font-family: '${fonts.font1}';
  src: url('${process.env.REACT_APP_BASE_URL}/assets/fonts/gotham_book-webfont.woff2') format('woff2'),
        url('${process.env.REACT_APP_BASE_URL}/assets/fonts/gotham_book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: '${fonts.font2}';
    src: url('${process.env.REACT_APP_BASE_URL}/assets/fonts/gotham_bold-webfont.woff2') format('woff2'),
         url('${process.env.REACT_APP_BASE_URL}/assets/fonts/gotham_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: '${fonts.font3}';
  src: url('../assets/fonts/gotham_medium-webfont.woff2') format('woff2'),
        url('../assets/fonts/gotham_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: '${fonts.font4}';
  src: url('${process.env.REACT_APP_BASE_URL}/assets/fonts/lato-regular-webfont.woff2') format('woff2'),
        url('${process.env.REACT_APP_BASE_URL}/assets/fonts/ato-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: '${fonts.font5}';
  src: url('../assets/fonts/lato-bold-webfont.woff2') format('woff2'),
        url('../assets/fonts/lato-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
`;

export default GlobalStyles;
