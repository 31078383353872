import styled from 'styled-components';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

export const StyledBanner = styled.div`
  ${flexGen('space-between', 'center', 'row')}
  width: 100%;
  padding-top: ${toRem(10)};
  padding-bottom: ${toRem(50)};
`;

export const StyledBannerBrowseHistory = styled.div`
  ${flexGen('center', 'center', 'row')}
`;
