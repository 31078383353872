import styled from 'styled-components';
import { colors } from 'styles/theme';
import toRem from 'utils/toRem';

export const StyledTag = styled.div`
  background-color: ${colors.color1};
  padding: ${toRem(4)};
  border-radius: ${toRem(6)};
  text-transform: uppercase;
  margin-right: ${toRem(4)};
  margin-left: ${toRem(4)};
  cursor: pointer;
`;
