import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import toRem from 'utils/toRem';
import { StyledSearchInput, StyledSearchWrapper } from './styles';

interface SearchInputrops {
  placeholder?: string;
  width: string;
  marginTop?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
  borderRadiusTopLeft?: string;
  borderRadiusTopRight?: string;
  borderRadiusBottomLeft?: string;
  borderRadiusBottomRight?: string;
}

const SearchInput: React.FC<SearchInputrops> = ({
  placeholder = '',
  width,
  marginTop = 0,
  marginBottom = 0,
  marginRight = 0,
  marginLeft = 0,
  borderRadiusTopLeft = toRem(4),
  borderRadiusTopRight = toRem(4),
  borderRadiusBottomLeft = toRem(4),
  borderRadiusBottomRight = toRem(4),
}) => {
  const [searchKey, setSearchKey] = useState<string>('');
  const history = useHistory();

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(e.target.value);
  };

  const onEnterKeyDownHandler = (keyboard: React.KeyboardEvent<HTMLInputElement>) => {
    if (keyboard.key === 'Enter' && searchKey !== '') {
      history.push(`/search?keyword=${searchKey}`);
    }
  };

  return (
    <StyledSearchWrapper
      width={width}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginRight={marginRight}
      marginLeft={marginLeft}
      borderRadiusBottomLeft={borderRadiusBottomLeft!}
      borderRadiusBottomRight={borderRadiusBottomRight!}
      borderRadiusTopLeft={borderRadiusTopLeft!}
      borderRadiusTopRight={borderRadiusTopRight!}
    >
      <StyledSearchInput
        type="text"
        placeholder={placeholder}
        onChange={(Event) => onChangeHandler(Event)}
        onKeyDown={onEnterKeyDownHandler}
      />
    </StyledSearchWrapper>
  );
};

SearchInput.defaultProps = {
  placeholder: '',
  marginTop: 0,
  marginBottom: 0,
  marginRight: 0,
  marginLeft: 0,
  borderRadiusTopLeft: toRem(4),
  borderRadiusTopRight: toRem(4),
  borderRadiusBottomLeft: toRem(4),
  borderRadiusBottomRight: toRem(4),
};

export default SearchInput;
