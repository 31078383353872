import React from 'react';
import SearchInput from 'components/SearchInput';
import Text from 'components/Text';
import TextLink from 'components/TextLink';
import toRem from 'utils/toRem';
import Icon from 'components/Icon';
import { fonts } from 'styles/theme';
import { StyledBanner, StyledBannerBrowseHistory } from './styles';

interface BannerProps {
  hierachy: Array<{ id: number; title: string; to: string; clickable: boolean }>;
}

const Banner: React.FC<BannerProps> = ({
  hierachy = [
    {
      id: 0,
      title: 'Knowledge base',
      to: '/',
      clickable: true,
    },
    {
      id: 1,
      title: 'Knowledge base',
      to: '/',
      clickable: true,
    },
  ],
}) => {
  const renderHierachyList = () => (
    <StyledBannerBrowseHistory>
      {hierachy.map(
        (item: { id: number; title: string; to: string; clickable: boolean }, index: number) => (
          <div key={item.id} style={{ display: 'flex', flexDirection: 'row' }}>
            <TextLink key={item.id} to={item.to} clickable={item.clickable}>
              <Text fontFamily={item.clickable ? fonts.font3 : fonts.font1}>{item.title}</Text>
            </TextLink>
            {index !== hierachy.length - 1 && (
              <Icon
                icon="arrow_right"
                width={12}
                height={8}
                viewbox="1 1 10 4"
                marginLeft={8}
                marginRight={8}
              />
            )}
          </div>
        ),
      )}
    </StyledBannerBrowseHistory>
  );

  return (
    <StyledBanner>
      {renderHierachyList()}
      <SearchInput placeholder="What can we help you with?" width={toRem(485)} />
    </StyledBanner>
  );
};

export default Banner;
