/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Route } from 'react-router';
import Spinner from 'components/Spinner';
import { checkIP } from 'utils/checkIP';

interface ProtectedRouteProps {
  exact: boolean;
  path: string;
  component: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ exact, path, component }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    checkIP().then((result) => {
      // 1. Check whether user type exists at local storage
      // Otherwise, it redirects to original fintel app
      if (document.cookie || result.state) {
        setIsLoading(false);
      } else {
        window.location.href = `${process.env.REACT_APP_APP_URI!}/login`;
      }
    });
  }, []);

  return isLoading ? <Spinner /> : <Route exact={exact} path={path} component={component} />;
};
export default ProtectedRoute;
