import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

export const StyledFeedbackWrapper = styled.div`
  ${flexGen('space-between', 'center', 'row')};
  width: 100%;
  height: ${toRem(104)};
  border-top: ${toRem(1)} solid ${colors.color11};
  border-bottom: ${toRem(1)} solid ${colors.color11};
`;

export const StyledFeedbackSurvey = styled.div`
  ${flexGen('center', 'center', 'row')};
`;

export const StyledFeedbackButtonContainer = styled.div`
  ${flexGen('center', 'center', 'row')};
`;
