import styled from 'styled-components';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';
import { colors } from 'styles/theme';

export const StyledServerErrorWrapper = styled.div`
  ${flexGen('center', 'center', 'column')};
  width: 100%;
  height: 100%;
`;

export const StyledServerErrorContainer = styled.div`
  ${flexGen('center', 'center', 'column')};
  max-width: ${toRem(500)};
  word-wrap: break-word;
  text-align: center;
`;

export const StyledServerErrorEmailLink = styled.a`
  text-decoration: none;
  color: ${colors.color1};
`;
