import React from 'react';
import { useHistory } from 'react-router-dom';
import Text from 'components/Text';
import Button from 'components/Button';
import { colors, fonts } from 'styles/theme';
import {
  StyledServerErrorWrapper,
  StyledServerErrorContainer,
  StyledServerErrorEmailLink,
} from './styles';

const ServerError: React.FC = () => {
  const history = useHistory();

  return (
    <StyledServerErrorWrapper>
      <StyledServerErrorContainer>
        <Text fontFamily={fonts.font2} fontSize={24} lineHeight={3.6}>
          Sorry, there was an error
        </Text>
        <Text fontFamily={fonts.font1} lineHeight={1.4}>
          Please try again later or contact us at
          {' '}
          <Text color={colors.color1}>
            <StyledServerErrorEmailLink href="mailto:info@fintelconnect.com">
              help@fintelconnect.com
            </StyledServerErrorEmailLink>
          </Text>
          {' '}
          if the problem persists.
        </Text>
        <Button
          onClick={() => {
            history.replace('/');
          }}
          marginTop={24}
        >
          Back to Homepage
        </Button>
      </StyledServerErrorContainer>
    </StyledServerErrorWrapper>
  );
};

export default ServerError;
