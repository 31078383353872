export const upsertAdd = (list: any[] | undefined, item: any) => {
  if (list) {
    list.push(item);
    return list;
  }
  const newList: any[] = [item];
  return newList;
};

export const upsertRemove = (list: any[] | undefined, item: any) => {
  if (list) {
    return list.filter((e: any) => e !== item);
  }
  const newList: any[] = [];
  return newList;
};
