import axios from 'axios';
import { getTypeFromCookie } from '../utils/getTypeFromCookie';

export const getTopic = async (id: string) => {
  const userType = await getTypeFromCookie();
  const result: any = await axios.get(`${process.env.REACT_APP_FINTEL_STRAPI_URL}/topics/${id}`, {
    headers: {
      type: userType,
    },
  });

  return result.data;
};
