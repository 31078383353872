import React, { useEffect, useState } from 'react';
import { StyledFloatingButton } from './styles';

interface FloatingButtonProps {
  backgroundColor: string;
  width: number;
  height: number;
  children: React.ReactNode;
}

const FloatingButton: React.FC<FloatingButtonProps> = ({
  backgroundColor,
  width,
  height,
  children,
}) => {
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      // window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <StyledFloatingButton
        backgroundColor={backgroundColor}
        width={width}
        height={height}
        onClick={scrollToTop}
        showButton={showButton}
        disabled={!showButton}
      >
        {children}
      </StyledFloatingButton>
    </>
  );
};

export default FloatingButton;
