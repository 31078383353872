import styled from 'styled-components';
import flexGen from '../../utils/flexGen';
import LightenDarkenColor from '../../utils/lightenDarkenColor';
import toRem from '../../utils/toRem';
import { colors, fonts } from '../../styles/theme';

interface IButton {
  readonly color?: string;
  readonly isRound?: boolean;
  readonly hasBorder?: boolean;
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
}

const Button = styled.button<IButton>`
  font-size: ${toRem(14)};
  font-weight: 400;
  font-family: ${fonts.font1};
  padding: ${toRem(10)} ${toRem(24)};
  ${flexGen('center', 'center')}

  margin-top: ${(props) => toRem(props.marginTop!)};
  margin-bottom: ${(props) => toRem(props.marginBottom!)};
  margin-left: ${(props) => toRem(props.marginLeft!)};
  margin-right: ${(props) => toRem(props.marginRight!)};

  ${(props) => {
    switch (props.color) {
      default:
        return `background-color: ${colors.color1}; 
        ${props.hasBorder ? `border: 1px solid ${colors.color1};` : 'border: none;'}
        color: ${colors.color2};
        
        :disabled {
          background-color: ${LightenDarkenColor(colors.color1, 40)};
          border-color: ${LightenDarkenColor(colors.color1, 40)};
        }`;

      case 'secondary':
        return `background-color: ${colors.color2};
        ${
  props.hasBorder
    ? `border: 1px solid ${LightenDarkenColor(colors.color2, -25)};`
    : 'border: none;'
}
        color: ${colors.color3};
        `;
      case 'positive':
        return `
          ${
  props.hasBorder
    ? `border: 1px solid ${LightenDarkenColor(colors.color2, -25)};`
    : 'border: none;'
}
          border-color: ${colors.color5};
          color: ${colors.color2};
          background-color: ${colors.color5};
          cursor: not-allowed;
          
        `;
      case 'negative':
        return `
          ${
  props.hasBorder
    ? `border: 1px solid ${LightenDarkenColor(colors.color2, -25)};`
    : 'border: none;'
}
          border-color: ${colors.color12};
          color: ${colors.color2};
          background-color: ${colors.color12};
          cursor: not-allowed;
        `;
    }
  }}

  ${(props) => (props.isRound ? 'border-radius: 4px;' : '')}
`;

Button.defaultProps = {
  color: 'primary',
  isRound: true,
  hasBorder: true,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
};
export default Button;
