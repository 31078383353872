import axios from 'axios';

export const updateArticle = async (id: string, body: any) => {
  const result: any = await axios.put(
    `${process.env.REACT_APP_FINTEL_STRAPI_URL}/articles/${id}`,
    body,
  );

  return result;
};
