import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Banner from 'components/Banner';
import Text from 'components/Text';
import ListContainerWrapper from 'components/ListContainerWrapper';
import ArticleItem from 'components/ArticleItem';
import Pagination from 'components/Pagination';
import { fonts } from 'styles/theme';
import Spinner from 'components/Spinner';
import { getTopic } from 'Apis/getTopic';
import { StyledTopicWrapper, StyledTopicHeader } from './styles';

const Topic: React.FC = () => {
  const urlQueries = new URLSearchParams(useLocation().search);
  const id: string | null = urlQueries.get('id');

  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [topic, setTopic] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    async function fetch() {
      try {
        if (id) {
          const res = await getTopic(id);

          setTopic(res);

          setLoading(false);
        } else {
          throw new Error('Category id is not found');
        }
      } catch (error: any) {
        if (error.response?.status < 500) {
          history.replace('/404');
        } else {
          history.replace('/error');
        }
      }
    }
    fetch();
  }, [history, id]);

  const onPageChangeHandler = (value: number) => {
    setCurrentPage(value);
  };

  const renderList = () => {
    if (topic) {
      return topic.articles
        .slice((currentPage - 1) * 4, currentPage * 4)
        .map((article: { id: number; title: string; description: string; category: any }) => (
          <ArticleItem
            key={article.id}
            to={`/article?id=${article.id}`}
            title={article.title}
            description={article.description}
            path={[
              { title: article.category.title, to: `/category?id=${article.category._id}` },
              { title: article.title, to: `/article?id=${article.id}` },
            ]}
          />
        ));
    }
    return <></>;
  };

  return (
    <StyledTopicWrapper>
      {topic && (
        <Banner
          hierachy={[
            {
              id: 0,
              title: 'Knowledge base',
              to: '/',
              clickable: true,
            },
            {
              id: 1,
              title: topic.name,
              to: '/',
              clickable: false,
            },
          ]}
        />
      )}
      {loading ? (
        <Spinner />
      ) : (
        <ListContainerWrapper>
          <StyledTopicHeader>
            {topic && (
              <Text fontSize={32} fontFamily={fonts.font2} lineHeight={1.4}>
                {topic.name}
              </Text>
            )}
          </StyledTopicHeader>
          {renderList()}
          {topic && topic.articles.length > 0 && (
            <Pagination
              currentPage={currentPage}
              maxPage={
                topic.articles.length % 4 === 0
                  ? Math.trunc(topic.articles.length / 4)
                  : Math.trunc(topic.articles.length / 4) + 1
              }
              onChange={onPageChangeHandler}
            />
          )}
        </ListContainerWrapper>
      )}
    </StyledTopicWrapper>
  );
};

export default Topic;
