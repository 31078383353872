import React, { Fragment } from 'react';
import Text from 'components/Text';
import TextLink from 'components/TextLink';
import Icon from 'components/Icon';

import { colors, fonts } from 'styles/theme';
import { StyledArticleItemWrapper, StyledArticleItemPath } from './styles';

interface ArticleItemProps {
  to: string;
  title: string;
  path: any[];
  description: string;
}

const ArticleItem: React.FC<ArticleItemProps> = ({
  to, title, path, description,
}) => (
  <StyledArticleItemWrapper>
    <TextLink to={to}>
      <Text fontSize={14} color={colors.color1} fontFamily={fonts.font3}>
        {title}
      </Text>
    </TextLink>
    <StyledArticleItemPath>
      <TextLink to="/">
        <Text fontFamily={fonts.font3} color={colors.color3}>
          Knowledge Base
        </Text>
      </TextLink>
      {path.map((item: { title: string; to: string }) => (
        <Fragment key={`${title}-${item.title}`}>
          <Icon
            icon="arrow_right"
            width={12}
            height={8}
            viewbox="1 1 10 4"
            marginLeft={4}
            marginRight={4}
          />
          <TextLink key={`${title}-${item.title}`} to={item.to}>
            <Text fontFamily={fonts.font3} color={colors.color3}>
              {item.title}
            </Text>
          </TextLink>
        </Fragment>
      ))}
    </StyledArticleItemPath>
    <Text lineHeight={1.2} fontFamily={fonts.font1} letterSpace={-0.3}>
      {description}
    </Text>
  </StyledArticleItemWrapper>
);

export default ArticleItem;
