import React from 'react';
import Logo from 'components/Logo';
import Underline from 'components/Underline';
import Text from 'components/Text';
import ExternalLink from 'components/ExternalLink';
import { colors, fonts } from 'styles/theme';
import Icon from 'components/Icon';
import SiteLink from 'components/SiteLink';
import { fintelLinks } from './links';
import {
  StyledFooterContainer,
  StyledFooterNav,
  StyledFooterCompany,
  StyledFooterNavList,
  StyledFooterIcons,
  StyledFooterIconButton,
  StyledFooterIconButtonLink,
  StyledExternalTextLink,
} from './styles';

const Footer: React.FC = () => (
  <StyledFooterContainer>
    <StyledFooterNav>
      <SiteLink to="/">
        <Logo size="large" backgroundColor="black" />
      </SiteLink>
      <StyledFooterNavList>
        <Text color={colors.color2} fontFamily={fonts.font3} fontSize={18}>
          Key Features
        </Text>
        <ExternalLink
          color={colors.color7}
          href={fintelLinks.brandFeatures}
          title="Brand Features"
          marginTop={22}
        />
        <ExternalLink
          color={colors.color7}
          href={fintelLinks.publisherFeatures}
          title="Publisher Features"
          marginTop={18}
        />
        <ExternalLink
          color={colors.color7}
          href={fintelLinks.fintelPerformance}
          title="Fintel Performance"
          marginTop={18}
        />
        <ExternalLink
          color={colors.color7}
          href={fintelLinks.fintelCheck}
          title="Fintel Check"
          marginTop={18}
        />
        <ExternalLink href="" color={colors.color7} title="Fintel Result" marginTop={18} />
      </StyledFooterNavList>

      <StyledFooterNavList>
        <Text color={colors.color2} fontFamily={fonts.font3} fontSize={18}>
          Content
        </Text>
        <ExternalLink color={colors.color7} href={fintelLinks.blog} title="Blog" marginTop={22} />
        <ExternalLink
          color={colors.color7}
          href={fintelLinks.pressRoom}
          title="Press Room"
          marginTop={18}
        />
      </StyledFooterNavList>

      <StyledFooterNavList>
        <Text color={colors.color2} fontFamily={fonts.font3} fontSize={18}>
          Connect
        </Text>
        <ExternalLink color={colors.color7} href={fintelLinks.about} title="About" marginTop={22} />
        <ExternalLink
          color={colors.color7}
          href={fintelLinks.contact}
          title="Contact"
          marginTop={18}
        />
        <SiteLink color={colors.color7} to="/" title="Help Center" marginTop={18} />
      </StyledFooterNavList>

      <StyledFooterIcons>
        <StyledFooterIconButtonLink target="_blank" href={fintelLinks.facebook}>
          <StyledFooterIconButton>
            <Icon icon="facebook" width={28} height={28} viewbox="0 0 24 24" marginRight={16} />
          </StyledFooterIconButton>
        </StyledFooterIconButtonLink>
        <StyledFooterIconButtonLink target="_blank" href={fintelLinks.linkedin}>
          <StyledFooterIconButton>
            <Icon icon="linkedin" width={28} height={28} viewbox="0 0 24 24" marginRight={16} />
          </StyledFooterIconButton>
        </StyledFooterIconButtonLink>
        <StyledFooterIconButtonLink target="_blank" href={fintelLinks.twitter}>
          <StyledFooterIconButton>
            <Icon icon="twitter" width={28} height={28} viewbox="0 0 24 24" marginRight={16} />
          </StyledFooterIconButton>
        </StyledFooterIconButtonLink>
      </StyledFooterIcons>
    </StyledFooterNav>
    <Underline color={colors.color7} />
    <StyledFooterCompany>
      <Text color={colors.color2} fontSize={14} fontFamily={fonts.font4}>
        © 2022 Fintel Connect Technologies Inc. |
        <StyledExternalTextLink href={fintelLinks.privacyPolicy} target="_blank">
          {'Privacy Policy '}
        </StyledExternalTextLink>
        | Built by
        <StyledExternalTextLink href={fintelLinks.convergence} target="_blank">
          Convergence
        </StyledExternalTextLink>
      </Text>
    </StyledFooterCompany>
  </StyledFooterContainer>
);

export default Footer;
