import styled from 'styled-components';
import flexGen from 'utils/flexGen';

export const StyledArticleContainerWrapper = styled.div`
  ${flexGen('space-between', 'flex-start', 'row')};
  width: 100%;
`;

export const StyledArticleContainerLeft = styled.div`
  width: 60%;
`;

export const StyledArticleContainerRight = styled.div`
  width: 30%;
  padding-top: 1.5%;
`;
