import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Banner from 'components/Banner';
import Text from 'components/Text';
import { colors, fonts } from 'styles/theme';
import Feedback from 'components/Feedback';
import Icon from 'components/Icon';
import TextLink from 'components/TextLink';
import Spinner from 'components/Spinner';
import {
  StyledArticleContainerWrapper,
  StyledArticleContainerLeft,
  StyledArticleContainerRight,
} from 'components/ArticleContainerWrapper';
import { getCategory } from 'Apis/getCategory';
import { updateCategory } from 'Apis/updateCategory';

import { upsertAdd, upsertRemove } from 'utils/upsertList';

import {
  StyledCategoryWrapper,
  StyledCategoryHeader,
  StyledCategoryTableContainer,
  StyledCategorySectionContainer,
  StyledCategorySectionItem,
  StyledCategoryTableContentWrapper,
} from './styles';

const Category: React.FC = () => {
  const urlQueries = new URLSearchParams(useLocation().search);
  const id: string | null = urlQueries.get('id');
  const history = useHistory();

  const uid = localStorage.getItem('uid');

  const [loading, setLoading] = useState<boolean>(true);
  const [category, setCategory] = useState<any>();

  const [helpful, setHelpful] = useState(false);
  const [unhelpful, setUnhelpful] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        if (id) {
          const categoryData = await getCategory(id);
          setCategory(categoryData[0]);

          if (categoryData[0].countList?.helpfulList && categoryData[0].countList?.unhelpfulList) {
            setHelpful(categoryData[0].countList.helpfulList.some((cid: string) => cid === uid));
            setUnhelpful(
              categoryData[0].countList.unhelpfulList.some((cid: string) => cid === uid),
            );
          }

          setLoading(false);
        } else {
          throw new Error('Category id is not found');
        }
      } catch (error: any) {
        if (error.response?.status < 500) {
          history.replace('/404');
        } else {
          history.replace('/error');
        }
      }
    }

    fetch();
  }, [id, history, uid]);

  const renderList = useCallback(() => {
    if (category) {
      return category.section.map(
        (section: { id: string; title: string; description: string; articleList: any[] }) => (
          <StyledCategorySectionContainer key={section.id} id={section.id}>
            <Text fontSize={24} lineHeight={3} fontFamily={fonts.font3}>
              {section.title}
            </Text>
            <Text lineHeight={1.4} fontFamily={fonts.font1} letterSpace={-0.3}>
              {section.description}
            </Text>
            {section.articleList.map(
              (articleItem: {
                id: string;
                title: string;
                description: string;
                articles: any[];
              }) => (
                <StyledCategorySectionItem key={articleItem.id}>
                  <Text fontFamily={fonts.font3} lineHeight={2.4}>
                    {articleItem.title}
                  </Text>
                  <Text lineHeight={1.4} fontFamily={fonts.font1} letterSpace={-0.3}>
                    {articleItem.description}
                  </Text>
                  {articleItem.articles.map((article: { id: string; title: string }) => (
                    <TextLink
                      to={`/article?id=${article.id}`}
                      marginTop={8}
                      marginBottom={8}
                      key={article.id}
                    >
                      <Text color={colors.color1} fontFamily={fonts.font3}>
                        {article.title}
                      </Text>
                      <Icon
                        icon="chev_right"
                        width={20}
                        height={20}
                        viewbox="0 0 20 20"
                        marginLeft={4}
                      />
                    </TextLink>
                  ))}
                </StyledCategorySectionItem>
              ),
            )}
          </StyledCategorySectionContainer>
        ),
      );
    }
    return <></>;
  }, [category]);

  const renderTableContentList = useCallback(() => {
    if (category) {
      return category.section.map((section: { id: string; title: string }) => (
        <StyledCategoryTableContentWrapper
          key={section.id}
          onClick={() => {
            const e = document.getElementById(section.id);
            const y = (e?.getBoundingClientRect().top || 0) + window.scrollY - 100;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }}
        >
          <Icon icon="flag" width={18} height={18} viewbox="0 0 18 18" marginRight={8} />
          <Text fontSize={14} fontFamily={fonts.font3}>
            {section.title}
          </Text>
        </StyledCategoryTableContentWrapper>
      ));
    }
    return <></>;
  }, [category]);

  const onPositiveCountHandler = async () => {
    const wasUnhelpful = unhelpful;
    setHelpful(true);
    setUnhelpful(false);
    const result = await updateCategory(category.id, {
      helpfulCount: category.helpfulCount + 1,
      ...(wasUnhelpful && { unhelpfulCount: category.unhelpfulCount - 1 }),
      countList: {
        helpfulList: upsertAdd(category.countList?.helpfulList, uid),
        unhelpfulList: upsertRemove(category.countList?.unhelpfulList, uid),
      },
    });
    setCategory(result.data);
  };

  const onNegativeCountHandler = async () => {
    const wasHelpful = helpful;
    setUnhelpful(true);
    setHelpful(false);
    const result = await updateCategory(category.id, {
      unhelpfulCount: category.unhelpfulCount + 1,
      ...(wasHelpful && { helpfulCount: category.helpfulCount - 1 }),
      countList: {
        helpfulList: upsertRemove(category.countList?.helpfulList, uid),
        unhelpfulList: upsertAdd(category.countList?.unhelpfulList, uid),
      },
    });
    setCategory(result.data);
  };

  return (
    <StyledCategoryWrapper>
      {category && (
        <Banner
          hierachy={[
            {
              id: 0,
              title: 'Knowledge base',
              to: '/',
              clickable: true,
            },
            {
              id: 1,
              title: category.title,
              to: '/',
              clickable: false,
            },
          ]}
        />
      )}
      {loading ? (
        <Spinner />
      ) : (
        <StyledArticleContainerWrapper>
          <StyledArticleContainerLeft>
            <StyledCategoryHeader>
              <Text fontSize={32} fontFamily={fonts.font2} color={colors.color3} lineHeight={1.8}>
                {category && category.title}
              </Text>
              <Text fontSize={14} fontFamily={fonts.font1} lineHeight={1.4} letterSpace={-0.3}>
                {category && category.description}
              </Text>
            </StyledCategoryHeader>
            {renderList()}
            {category && (
              <Feedback
                helpful={helpful}
                unhelpful={unhelpful}
                onPositiveChangeHandler={onPositiveCountHandler}
                onNegativeChangeHandler={onNegativeCountHandler}
              />
            )}
          </StyledArticleContainerLeft>
          <StyledArticleContainerRight>
            <Text fontSize={24} fontFamily={fonts.font2}>
              Table of Contents
            </Text>
            <StyledCategoryTableContainer>{renderTableContentList()}</StyledCategoryTableContainer>
          </StyledArticleContainerRight>
        </StyledArticleContainerWrapper>
      )}
    </StyledCategoryWrapper>
  );
};

export default Category;
