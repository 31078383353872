import React from 'react';
import iconList from './iconList';
import { StyledIconWrapper } from './styles';

interface IconProps {
  icon: string;
  height?: number;
  width?: number;
  viewbox?: string;
  marginTop?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
}

const Icon: React.FC<IconProps> = ({
  icon,
  height = 20,
  width = 20,
  viewbox = '0 0 20 20',
  marginTop = 0,
  marginBottom = 0,
  marginRight = 0,
  marginLeft = 0,
}) => (
  <StyledIconWrapper
    marginTop={marginTop}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    marginRight={marginRight}
  >
    <svg
      width={width}
      height={height}
      viewBox={viewbox}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: iconList[icon] }}
    />
  </StyledIconWrapper>
);

Icon.defaultProps = {
  height: 20,
  width: 20,
  viewbox: '0 0 20 20',
  marginTop: 0,
  marginBottom: 0,
  marginRight: 0,
  marginLeft: 0,
};

export default Icon;
