import React from 'react';
import Button from 'components/Button';
import Text from 'components/Text';
import { fonts } from 'styles/theme';
import Icon from 'components/Icon';
import {
  StyledFeedbackWrapper,
  StyledFeedbackSurvey,
  StyledFeedbackButtonContainer,
} from './styles';

interface FeedbackProps {
  helpful: boolean;
  unhelpful: boolean;
  onPositiveChangeHandler(): void;
  onNegativeChangeHandler(): void;
}

const Feedback: React.FC<FeedbackProps> = ({
  helpful,
  unhelpful,
  onPositiveChangeHandler = () => {},
  onNegativeChangeHandler = () => {},
}) => (
  <StyledFeedbackWrapper>
    <StyledFeedbackSurvey>
      <Icon icon="help" viewbox="0 0 18 18" marginRight={8} />
      <Text fontFamily={fonts.font2} fontSize={14}>
        Was this article helpful?
      </Text>
    </StyledFeedbackSurvey>
    <StyledFeedbackButtonContainer>
      <Button
        color={helpful ? 'positive' : 'secondary'}
        marginRight={16}
        onClick={onPositiveChangeHandler}
      >
        <Icon icon={helpful ? 'check_white' : 'check'} marginRight={8} />
        Yes, thanks!
      </Button>
      <Button color={unhelpful ? 'negative' : 'secondary'} onClick={onNegativeChangeHandler}>
        <Icon icon={unhelpful ? 'close_white' : 'close'} marginRight={8} />
        Not really
      </Button>
    </StyledFeedbackButtonContainer>
  </StyledFeedbackWrapper>
);

export default Feedback;
