import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

export const StyledRelatedArticleWrapper = styled.div`
  ${flexGen('flex-start', 'flex-start', 'column')};
  width: 100%;
  padding: ${toRem(24)} 0 ${toRem(24)} 0;
  word-wrap: break-word;
`;

export const StyledRelatedArticlePath = styled.div`
  ${flexGen('flex-start', 'center', 'row')}
  padding-top: ${toRem(8)};

  a {
    text-decoration: none;
  }

  svg {
    fill: ${colors.color1};
  }
`;
