import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

export const StyledMainPageWrapper = styled.div`
  width: 100%;
`;

export const StyledMainPageHeader = styled.div`
  ${flexGen('center', 'center', 'column')};
  position: relative;
  background-color: ${colors.color3};
  width: 100%;
  height: ${toRem(320)};
  overflow: hidden;
`;

export const StyledMainPageContainer = styled.div`
  width: 100%;
  padding: ${toRem(64)} 8% ${toRem(64)} 8%;
`;

export const StyledMainPageMenus = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  margin-top: ${toRem(50)};
  margin-bottom: ${toRem(100)};
`;

export const StyledMainPageMenu = styled.div`
  ${flexGen('space-between', 'center', 'row')};
  width: 100%;
  height: 100%;
  padding: ${toRem(24)};
`;

export const StyledMainPageIcon = styled.div`
  ${flexGen('center', 'center', 'row')};
  width: ${toRem(92)};
  height: ${toRem(92)};
  border-radius: 50%;
  background-color: ${colors.color10};
  margin-right: 30px;
`;

export const StyledMainPageMenuContent = styled.div`
  ${flexGen('space-evenly', 'flex-start', 'column')};
  width: 80%;
  height: 100%;
`;

export const StyledMainPageTopics = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  margin-top: ${toRem(50)};
`;

export const StyledMainPageTopic = styled.div`
  ${flexGen('center', 'center', 'column')};
  width: 100%;
  height: 100%;
  padding: ${toRem(24)};
`;
