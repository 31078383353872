import axios from 'axios';
import { getTypeFromCookie } from '../utils/getTypeFromCookie';

export const getCategories = async () => {
  const userType = await getTypeFromCookie();
  const result: any = await axios.get(
    `${process.env.REACT_APP_FINTEL_STRAPI_URL}/categories?_sort=_id:ASC`,
    {
      headers: {
        type: userType,
      },
    },
  );

  return result.data;
};
