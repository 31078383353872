import styled from 'styled-components';
import toRem from 'utils/toRem';

interface IStyledExternalLink {
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
}

export const StyledExternalLink = styled.a<IStyledExternalLink>`
  margin-top: ${(props) => toRem(props.marginTop)};
  margin-bottom: ${(props) => toRem(props.marginBottom)};
  margin-right: ${(props) => toRem(props.marginRight)};
  margin-left: ${(props) => toRem(props.marginLeft)};
  text-decoration: none;
  cursor: pointer;
`;
