import React from 'react';
import { useHistory } from 'react-router-dom';
import { StyledBoxContainer } from './styles';

interface BoxContainerProps {
  to: string;
  children: React.ReactNode;
}

const BoxContainer: React.FC<BoxContainerProps> = ({ to, children }) => {
  const history = useHistory();

  const onBoxClickHandler = () => {
    history.push(to);
  };

  return <StyledBoxContainer onClick={onBoxClickHandler}>{children}</StyledBoxContainer>;
};

export default BoxContainer;
