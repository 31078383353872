import React from 'react';
import { StyledListWrapper } from './styles';

interface ListContainerWrapperProps {
  children: React.ReactNode;
}

const ListContainerWrapper: React.FC<ListContainerWrapperProps> = ({ children }) => <StyledListWrapper>{children}</StyledListWrapper>;

export default ListContainerWrapper;
