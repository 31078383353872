export const fintelLinks: { [key: string]: string } = {
  fintelConnect: 'https://www.fintelconnect.com',
  dashboard: 'https://app.fintelconnect.com',
  messageHub: '',
  brandFeatures: 'https://www.fintelconnect.com/brands/',
  publisherFeatures: 'https://www.fintelconnect.com/publishers/',
  fintelPerformance: 'https://www.fintelconnect.com/fintel-performance/',
  fintelCheck: 'https://www.fintelconnect.com/fintel-check/',
  fintelResult: '',
  blog: 'https://www.fintelconnect.com/blog/',
  pressRoom: 'https://www.fintelconnect.com/press/',
  about: 'https://www.fintelconnect.com/about-us/',
  contact: 'https://www.fintelconnect.com/contact/',
  helpCenter: '',
  facebook: 'https://www.facebook.com/fintelconnect/',
  linkedin: 'https://ca.linkedin.com/company/fintelconnect',
  twitter: 'https://twitter.com/FintelConnect',
  privacyPolicy: 'https://www.fintelconnect.com/privacy-policy/',
  convergence: 'https://www.cctech.io',
};
