import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navigator from 'components/Navigator';
import Footer from 'components/Footer';
import FloatingButton from 'components/FloatingButton';
import Icon from 'components/Icon';
import Main from 'pages/Main';
import Search from 'pages/Search';
import Topic from 'pages/Topic';
import Category from 'pages/Category';
import Article from 'pages/Article';
import Auth from 'pages/Auth';
import URLNotFound from 'pages/URLNotFound';
import ServerError from 'pages/ServerError';

import ProtectedRoute from 'ProtectedRoute';
import { colors } from 'styles/theme';
import { checkAuthCookies } from 'utils/checkAuthCookies';
import { deleteAuthCookies } from 'utils/deleteAuthCookies';
import { AppContainer } from './styles/globalStyles';

const Routes = () => {
  deleteAuthCookies();
  useEffect(() => {
    window.setInterval(() => {
      checkAuthCookies('app.fintelconnect.com/login');
    }, 10000);
  }, []);

  return (
    <BrowserRouter forceRefresh>
      {/* Main Padding Left/Right 8% */}
      <AppContainer>
        <Navigator />
        <Switch>
          <Route exact path="/auth">
            <Auth />
          </Route>
          <ProtectedRoute exact path="/" component={Main} />
          <ProtectedRoute exact path="/search" component={Search} />
          <ProtectedRoute exact path="/topic" component={Topic} />
          <ProtectedRoute exact path="/category" component={Category} />
          <ProtectedRoute exact path="/article" component={Article} />
          <Route path="/error">
            <ServerError />
          </Route>
          <Route path="*">
            <URLNotFound />
          </Route>
        </Switch>
        <Footer />
        <FloatingButton backgroundColor={colors.color6} width={60} height={60}>
          <Icon icon="chev_up" width={16} height={16} viewbox="0 0 16 16" />
        </FloatingButton>
      </AppContainer>
    </BrowserRouter>
  );
};

export default Routes;
