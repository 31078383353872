export const IconMap: {
  [key: string]: {
    icon: string;
    width: number;
    height: number;
    viewbox: string;
  };
} = {
  'getting-started': {
    icon: 'getting_started',
    width: 60,
    height: 40,
    viewbox: '0 0 60 40',
  },
  'the-basics': {
    icon: 'the_basics',
    width: 33,
    height: 48,
    viewbox: '0 0 33 48',
  },
  'data-and-analytics': {
    icon: 'data_analytics',
    width: 43,
    height: 46,
    viewbox: '0 0 43 46',
  },
  troubleshooting: {
    icon: 'troubleshooting',
    width: 59,
    height: 46,
    viewbox: '0 0 59 46',
  },
  'message-hub': {
    icon: 'message_hub',
    width: 58,
    height: 33,
    viewbox: '0 0 58 33',
  },
  'fintel-check': {
    icon: 'fintel_check',
    width: 30,
    height: 39,
    viewbox: '0 0 30 39',
  },
};
