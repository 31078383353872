export const colors = {
  color1: '#F78D2D',
  color2: '#ffffff',
  color3: '#253746',
  color4: '#696969',
  color5: '#4BC7C0',
  color6: '#C2E3FF',
  color7: '#92ADCB',
  color8: '#3A5264',
  color9: '#224058',
  color10: '#F5F5F5',
  color11: '#E6E6E6',
  color12: '#FF5D68',
};

export const fonts = {
  font1: 'GothamBook',
  font2: 'GothamBold',
  font3: 'GothamMedium',
  font4: 'Lato',
  font5: 'Lato_Bold',
};
