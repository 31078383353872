import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Banner from 'components/Banner';
import Text from 'components/Text';
import { fonts } from 'styles/theme';
import ListContainerWrapper from 'components/ListContainerWrapper';
import ArticleItem from 'components/ArticleItem';
import Pagination from 'components/Pagination';
import Spinner from 'components/Spinner';
import { getSearchTag } from 'Apis/getSearchTag';
import { getSearchKeyword } from 'Apis/getSearchKeyword';
import { StyledSearchWrapper, StyledSearchHeader } from './styles';

const Search: React.FC = () => {
  const urlQueries = new URLSearchParams(useLocation().search);
  const keyword: string | null = urlQueries.get('keyword');
  const tag: string | null = urlQueries.get('tag');

  const history = useHistory();

  const hierachy: { id: number; title: string; to: string; clickable: boolean }[] = [
    {
      id: 0,
      title: 'Knowledge base',
      to: '/',
      clickable: true,
    },
    {
      id: 1,
      title: keyword ? 'Search results' : 'Tag results',
      to: '/',
      clickable: false,
    },
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [articles, setArticles] = useState<any[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    async function fetch() {
      try {
        if (tag) {
          const articlesData = await getSearchTag(tag);

          setArticles(articlesData);
        } else if (keyword) {
          const articlesData = await getSearchKeyword(keyword);

          setArticles(articlesData);
        } else {
          throw new Error('Category id is not found');
        }
        setLoading(false);
      } catch (error: any) {
        if (error.response?.status < 500) {
          history.replace('/404');
        } else {
          history.replace('/error');
        }
      }
    }
    fetch();
  }, [history, keyword, tag]);

  const onPageChangeHandler = (value: number) => {
    setCurrentPage(value);
  };

  const renderList = () => {
    if (articles) {
      return articles.slice((currentPage - 1) * 4, currentPage * 4).map((article: any) => (
        <ArticleItem
          key={article.id}
          to={`/article?id=${article.id}`}
          title={article.title}
          description={article.description}
          path={[
            { title: article.category.title, to: `/category?id=${article.category._id}` },
            { title: article.title, to: `/article?id=${article.id}` },
          ]}
        />
      ));
    }
    return <></>;
  };

  return (
    <StyledSearchWrapper>
      <Banner hierachy={hierachy} />

      {loading ? (
        <Spinner />
      ) : (
        <ListContainerWrapper>
          <StyledSearchHeader>
            {keyword && articles && (
              <>
                <Text fontSize={32} fontFamily={fonts.font2} lineHeight={1.4}>
                  Search results
                </Text>
                <Text fontSize={14} fontFamily={fonts.font1} lineHeight={1.2}>
                  {articles.length}
                  {' '}
                  search results for &quot;
                  {keyword}
                  &quot;
                </Text>
              </>
            )}

            {tag && articles && (
              <>
                <Text fontSize={32} fontFamily={fonts.font2} lineHeight={1.4}>
                  Tag &quot;
                  {tag}
                  &quot; results
                </Text>
                <Text fontSize={14} fontFamily={fonts.font1} lineHeight={1.2}>
                  {articles.length}
                  {' '}
                  tag results for &quot;
                  {tag}
                  &quot;
                </Text>
              </>
            )}
          </StyledSearchHeader>
          {renderList()}
          {articles && articles.length > 0 && (
            <Pagination
              currentPage={currentPage}
              maxPage={
                articles.length % 4 === 0
                  ? Math.trunc(articles.length / 4)
                  : Math.trunc(articles.length / 4) + 1
              }
              onChange={onPageChangeHandler}
            />
          )}
        </ListContainerWrapper>
      )}
    </StyledSearchWrapper>
  );
};

export default Search;
