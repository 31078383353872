import React from 'react';
import { Link } from 'react-router-dom';
import { colors, fonts } from 'styles/theme';
import Text from 'components/Text';

import { StyledSiteLink } from './styles';

interface SiteLinkProps {
  to: string;
  title?: string | undefined;
  color?: string;
  size?: number;
  marginTop?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
}

const SiteLink: React.FC<SiteLinkProps> = ({
  color = colors.color7,
  size = 16,
  to,
  title,
  children,
  marginTop = 0,
  marginBottom = 0,
  marginLeft = 0,
  marginRight = 0,
}) => (
  <StyledSiteLink
    marginTop={marginTop}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    marginRight={marginRight}
  >
    <Link to={to}>
      <Text color={color} fontFamily={fonts.font3} fontSize={size}>
        {title}
        {children}
      </Text>
    </Link>
  </StyledSiteLink>
);

SiteLink.defaultProps = {
  title: undefined,
  color: colors.color7,
  size: 16,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
};

export default SiteLink;
