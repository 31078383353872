import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

export const StyledArticleWrapper = styled.div`
  ${flexGen('flex-start', 'center', 'column')};
  width: 100%;
  padding: ${toRem(32)} 8% ${toRem(64)} 8%;
`;

export const StyledArticleHeader = styled.div`
  ${flexGen('flex-start', 'flex-start', 'column')}
  width: 100%;
  padding-bottom: ${toRem(40)};
`;

export const StyledArticleTableContainer = styled.div`
  margin-top: ${toRem(24)};
  margin-bottom: ${toRem(60)};
`;

export const StyledArticleContentWrapper = styled.div`
  width: 100%;
  border-top: ${toRem(1)} solid ${colors.color11};
`;

export const StyledArticleContentContainer = styled.div`
  ${flexGen('flex-start', 'flex-start', 'column')};
  padding-bottom: ${toRem(32)};
  padding-top: ${toRem(32)};
  border-bottom: ${toRem(1)} solid ${colors.color11};
`;

export const StyledArticleTableContentWrapper = styled.div`
  ${flexGen('flex-start', 'center', 'row')}
  cursor: pointer;
`;

export const StyledArticleTagContainer = styled.div`
  ${flexGen('flex-start', 'center', 'row')};
  padding-bottom: ${toRem(32)};
  padding-top: ${toRem(32)};
`;

export const StyledArticleRelatedContainer = styled.div`
  /* margin-top: ${toRem(24)}; */
  padding-top: 1.5%;
`;
