import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

export const StyledCategoryWrapper = styled.div`
  ${flexGen('flex-start', 'center', 'column')};
  width: 100%;
  padding: ${toRem(32)} 8% ${toRem(64)} 8%;
`;

export const StyledCategoryHeader = styled.div`
  ${flexGen('flex-start', 'flex-start', 'column')}
  width: 100%;
  padding-bottom: ${toRem(40)};
`;

export const StyledCategorySectionContainer = styled.div`
  ${flexGen('flex-start', 'flex-start', 'column')};
  border-top: ${toRem(1)} solid ${colors.color11};
`;

export const StyledCategorySectionItem = styled.div`
  ${flexGen('flex-start', 'flex-start', 'column')};
  padding-top: ${toRem(8)};
  padding-bottom: ${toRem(8)};

  svg {
    fill: ${colors.color1};
  }
`;

export const StyledCategoryTableContainer = styled.div`
  margin-top: ${toRem(24)};
`;

export const StyledCategoryTableContentWrapper = styled.div`
  ${flexGen('flex-start', 'center', 'row')}
  cursor: pointer;
`;
