import styled from 'styled-components';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

interface IStyledFloatingButton {
  backgroundColor: string;
  width: number;
  height: number;
  showButton?: boolean;
}

export const StyledFloatingButton = styled.button<IStyledFloatingButton>`
  ${flexGen('center', 'center', 'row')};
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => toRem(props.width)};
  height: ${(props) => toRem(props.height)};
  border-radius: 50%;
  border: none;
  cursor: pointer;
  opacity: ${(props) => (props.showButton ? 1 : 0)};
  transition: opacity 1s;
  position: fixed;
  bottom: 60px;
  right: 60px;
`;
