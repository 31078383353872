import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

interface IStyledNavigationContainer {
  navChange: boolean;
  isRoot: boolean;
}

export const StyledNavigatorContainer = styled.div<IStyledNavigationContainer>`
  ${flexGen('space-between', 'center', 'row')};
  position: sticky;
  top: 0;
  width: 100%;
  padding: ${toRem(24)} 8% ${toRem(24)} 8%;
  z-index: 100;
  background-color: ${(props) => (props.isRoot && !props.navChange ? colors.color3 : colors.color2)};
  ${(props) => (props.isRoot && !props.navChange
    ? 'box-shadow: none'
    : 'box-shadow: 0 -0.0625rem 1.0625rem -0.125rem rgb(0 0 0 / 15%)')};
`;

export const StyledNavigatorMenu = styled.div`
  ${flexGen('space-between', 'center', 'row')}
  width: 50%;
  height: max-content;
`;
