import React from 'react';
import Text from 'components/Text';
import TextLink from 'components/TextLink';
import Icon from 'components/Icon';
import { colors, fonts } from 'styles/theme';
import { StyledRelatedArticleWrapper, StyledRelatedArticlePath } from './styles';

interface RelatedArticleProps {
  id: string;
  title: string;
  description: string;
}

const RelatedArticle: React.FC<RelatedArticleProps> = ({ id, title, description }) => (
  <StyledRelatedArticleWrapper>
    <Text fontSize={14} color={colors.color3} fontFamily={fonts.font3} lineHeight={1.4}>
      {title}
    </Text>
    <Text lineHeight={1.2} fontFamily={fonts.font1} letterSpace={-0.3}>
      {description}
    </Text>

    <StyledRelatedArticlePath>
      <TextLink to={`/article?id=${id}`}>
        <Text color={colors.color1} fontFamily={fonts.font3}>
          Read more
        </Text>
        <Icon icon="chev_right" viewbox="0 0 17 17" marginLeft={4} width={14} height={14} />
      </TextLink>
    </StyledRelatedArticlePath>
  </StyledRelatedArticleWrapper>
);

export default RelatedArticle;
