import axios from 'axios';
import { getTypeFromCookie } from '../utils/getTypeFromCookie';

export const getTopics = async () => {
  const userType = await getTypeFromCookie();
  const topicMap: {
    [key: string]: {
      id: string;
      name: string;
      description: string;
    };
  } = {};

  const result: any = await axios.get(`${process.env.REACT_APP_FINTEL_STRAPI_URL}/articles`, {
    headers: {
      type: userType,
    },
  });

  result.data.forEach((article: any) => {
    if (article.topic) {
      topicMap[article.topic.id] = {
        id: article.topic.id,
        name: article.topic.name,
        description: article.topic.description,
      };
    }
  });

  return Object.keys(topicMap).map((key) => topicMap[key]);
};
