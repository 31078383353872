import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from 'components/Spinner';
import { getUserByToken } from 'Apis/getUserByToken';
import { getTokenFromCookie } from 'utils/getTokenFromCookie';
import { checkIP } from 'utils/checkIP';

const Auth: React.FC = () => {
  const urlQueries = new URLSearchParams(useLocation().search);
  const accessToken: string | null = urlQueries.get('accessToken');
  const idToken: string | null = urlQueries.get('idToken');

  const history = useHistory();

  async function fetch(accToken: string) {
    try {
      const res = await getUserByToken(accToken);

      if (res.status !== 200) {
        throw new Error('User is not found');
      }
      localStorage.setItem('uid', res.data[0]._id);
      history.replace('/');
    } catch (error) {
      history.replace('/404');
    }
  }

  useEffect(() => {
    checkIP().then((result) => {
      const aToken = getTokenFromCookie();
      if (!result.state) {
        if (aToken) {
          fetch(aToken);
        } else {
          window.location.replace(`${process.env.REACT_APP_APP_URI!}/login`);
        }
      }
    });
  }, [accessToken, history, idToken]);

  return <Spinner />;
};

export default Auth;
