import styled from 'styled-components';

interface IStyledUnderline {
  color: string;
}

const StyledUnderline = styled.div<IStyledUnderline>`
  width: 100%;
  border-bottom: 1px ${(props) => props.color} solid;
`;

export default StyledUnderline;
