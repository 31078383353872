import jwt, { JwtPayload } from 'jsonwebtoken';
import { checkIP } from 'utils/checkIP';

export const getTypeFromCookie = async () => {
  const value = document.cookie;
  const fcCookie = value.split('; ').find((cookie) => cookie.split('=')[0] === '__KB_FCT');
  if (fcCookie && fcCookie.indexOf('=') !== -1) {
    return fcCookie.split('=')[1];
  }
  const result = await checkIP();
  if (result.state) {
    document.cookie = `__KB_FCT=${result.value}; expires=Tue, 31 Dec 2030 23:59:59 UTC; path=/;`;
    return result.value;
  }
  const secret: any = process.env.REACT_APP_COOKIE_SECRET_KEY;
  const userTypeCookie = document.cookie
    .split(';')
    .find((cookie: any) => cookie.trim().split('=')[0] === 'FA_UT')
    ?.trim()
    .split('=')[1];
  if (userTypeCookie) {
    const verifiedToken = jwt.verify(userTypeCookie, secret) as JwtPayload;
    return verifiedToken.data;
  }
  return '';
};
