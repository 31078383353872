import React from 'react';
import { colors, fonts } from 'styles/theme';
import Text from 'components/Text';

import { StyledExternalLink } from './styles';

interface ExternalLinkProps {
  href: string;
  title: string;
  color?: string;
  size?: number;
  marginTop?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({
  color = colors.color7,
  size = 16,
  href,
  title,
  marginTop = 0,
  marginBottom = 0,
  marginLeft = 0,
  marginRight = 0,
}) => (
  <StyledExternalLink
    marginTop={marginTop}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    marginRight={marginRight}
    href={href}
    target="_blank"
  >
    <Text color={color} fontFamily={fonts.font3} fontSize={size}>
      {title}
    </Text>
  </StyledExternalLink>
);

ExternalLink.defaultProps = {
  color: colors.color7,
  size: 16,
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
};

export default ExternalLink;
