import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Banner from 'components/Banner';
import Text from 'components/Text';
import { colors, fonts } from 'styles/theme';
import Feedback from 'components/Feedback';
import Icon from 'components/Icon';
import Tag from 'components/Tag';
import Spinner from 'components/Spinner';
import { getArticle } from 'Apis/getArticle';
import { getRelatedArticles } from 'Apis/getRelatedArticles';
import { updateArticle } from 'Apis/updateArticle';
import { upsertAdd, upsertRemove } from 'utils/upsertList';
import Parser from 'html-react-parser';

import {
  StyledArticleContainerWrapper,
  StyledArticleContainerLeft,
  StyledArticleContainerRight,
} from 'components/ArticleContainerWrapper';
import RelatedArticle from './components/RelatedArticle';
import {
  StyledArticleWrapper,
  StyledArticleHeader,
  StyledArticleTableContainer,
  StyledArticleContentWrapper,
  StyledArticleContentContainer,
  StyledArticleTableContentWrapper,
  StyledArticleTagContainer,
  StyledArticleRelatedContainer,
} from './styles';

const Article: React.FC = () => {
  const urlQueries = new URLSearchParams(useLocation().search);
  const id: string | null = urlQueries.get('id');
  const history = useHistory();

  const uid = localStorage.getItem('uid');

  const [loading, setLoading] = useState<boolean>(true);
  const [article, setArticle] = useState<any>();
  const [relatedArticles, setRelatedArticles] = useState<any>();
  const [helpful, setHelpful] = useState(false);
  const [unhelpful, setUnhelpful] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        if (id) {
          const articleData = await getArticle(id);
          setArticle(articleData);
          const related = await getRelatedArticles(id);

          setRelatedArticles(related);

          if (articleData.countList?.helpfulList && articleData.countList?.unhelpfulList) {
            setHelpful(articleData.countList.helpfulList.some((cid: string) => cid === uid));
            setUnhelpful(articleData.countList.unhelpfulList.some((cid: string) => cid === uid));
          }

          setLoading(false);
        } else {
          throw new Error('Category id is not found');
        }
      } catch (error: any) {
        if (error.response?.status < 500) {
          history.replace('/404');
        } else {
          history.replace('/error');
        }
      }
    }
    setLoading(true);
    fetch();
  }, [id, history, uid]);

  const renderContentList = useCallback(
    () => article.content.map((content: { id: string; title: string; body: string }) => {
      const body = Parser(content.body);
      return (
        <StyledArticleContentContainer key={content.id} id={content.id}>
          <Text fontFamily={fonts.font3} fontSize={24} lineHeight={1.8}>
            {content.title}
          </Text>
          <Text className="ck-content" fontFamily={fonts.font1} letterSpace={-0.3}>
            {body}
          </Text>
        </StyledArticleContentContainer>
      );
    }),
    [article],
  );

  const renderTagList = useCallback(
    () => article.tag.map((tag: { id: string; name: string }) => <Tag key={tag.id} title={tag.name} />),
    [article],
  );

  const renderTableContentList = useCallback(
    () => article.content.map((content: { id: string; title: string }) => (
      <StyledArticleTableContentWrapper
        key={content.id}
        onClick={() => {
          const e = document.getElementById(content.id);
          const y = (e?.getBoundingClientRect().top || 0) + window.scrollY - 100;
          window.scroll({ top: y, behavior: 'smooth' });
        }}
      >
        <Icon icon="flag" width={18} height={18} viewbox="0 0 18 18" marginRight={8} />
        <Text fontSize={14} fontFamily={fonts.font3}>
          {content.title}
        </Text>
      </StyledArticleTableContentWrapper>
    )),
    [article],
  );

  const renderRelatedArticleList = useCallback(
    () => relatedArticles
      && relatedArticles.map((articleObj: { id: string; title: string; description: string }) => (
        <RelatedArticle
          key={articleObj.id}
          id={articleObj.id}
          title={articleObj.title}
          description={articleObj.description}
        />
      )),
    [relatedArticles],
  );

  const onPositiveCountHandler = async () => {
    const wasUnhelpful = unhelpful;
    setHelpful(true);
    setUnhelpful(false);
    const result = await updateArticle(article.id, {
      helpfulCount: article.helpfulCount + 1,
      ...(wasUnhelpful && { unhelpfulCount: article.unhelpfulCount - 1 }),
      countList: {
        helpfulList: upsertAdd(article.countList?.helpfulList, uid),
        unhelpfulList: upsertRemove(article.countList?.unhelpfulList, uid),
      },
    });
    setArticle(result.data);
  };

  const onNegativeCountHandler = async () => {
    const wasHelpful = helpful;
    setUnhelpful(true);
    setHelpful(false);
    const result = await updateArticle(article.id, {
      unhelpfulCount: article.unhelpfulCount + 1,
      ...(wasHelpful && { helpfulCount: article.helpfulCount - 1 }),
      countList: {
        helpfulList: upsertRemove(article.countList?.helpfulList, uid),
        unhelpfulList: upsertAdd(article.countList?.unhelpfulList, uid),
      },
    });
    setArticle(result.data);
  };

  return (
    <StyledArticleWrapper>
      {article && (
        <Banner
          hierachy={[
            {
              id: 0,
              title: 'Knowledge base',
              to: '/',
              clickable: true,
            },
            {
              id: 1,
              title: article.category.title,
              to: `/category?id=${article.category.id}`,
              clickable: true,
            },
            {
              id: 2,
              title: article.title,
              to: '/',
              clickable: false,
            },
          ]}
        />
      )}
      {loading ? (
        <Spinner />
      ) : (
        <StyledArticleContainerWrapper>
          <StyledArticleContainerLeft>
            <StyledArticleHeader>
              <Text fontSize={32} fontFamily={fonts.font2} color={colors.color3} lineHeight={1.8}>
                {article && article.title}
              </Text>
              <Text fontSize={14} fontFamily={fonts.font1} letterSpace={-0.3} lineHeight={1.2}>
                {article && article.description}
              </Text>
            </StyledArticleHeader>
            <StyledArticleContentWrapper>
              {article && renderContentList()}
            </StyledArticleContentWrapper>
            <StyledArticleTagContainer>
              <Text color={colors.color3} fontFamily={fonts.font2}>
                Tags:
              </Text>
              {article && renderTagList()}
            </StyledArticleTagContainer>

            {article && (
              <Feedback
                helpful={helpful}
                unhelpful={unhelpful}
                onPositiveChangeHandler={onPositiveCountHandler}
                onNegativeChangeHandler={onNegativeCountHandler}
              />
            )}
          </StyledArticleContainerLeft>
          <StyledArticleContainerRight>
            <Text fontSize={24} fontFamily={fonts.font2}>
              Table of Contents
            </Text>
            <StyledArticleTableContainer>
              {article && renderTableContentList()}
            </StyledArticleTableContainer>

            <Text fontSize={24} fontFamily={fonts.font2}>
              Related articles
            </Text>
            <StyledArticleRelatedContainer>
              {renderRelatedArticleList()}
            </StyledArticleRelatedContainer>
          </StyledArticleContainerRight>
        </StyledArticleContainerWrapper>
      )}
    </StyledArticleWrapper>
  );
};

export default Article;
