import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

export const StyledArticleItemWrapper = styled.div`
  ${flexGen('flex-start', 'flex-start', 'column')};
  width: 100%;
  border-top: ${toRem(1)} solid ${colors.color11};
  padding: ${toRem(24)} 0 ${toRem(24)} 0;
  word-wrap: break-word;
`;

export const StyledArticleItemPath = styled.div`
  ${flexGen('flex-start', 'center', 'row')}
  padding-top: ${toRem(8)};
  padding-bottom: ${toRem(12)};
`;
