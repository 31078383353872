import styled from 'styled-components';

interface IParallelogram {
  color: string;
  width: string;
  height: string;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
}

const Parallelogram = styled.div<IParallelogram>`
  position: absolute;
  transform: skewY(-20deg);
  background-color: ${(props) => props.color};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) => (props.top ? `top: ${props.top}` : '')};
  ${(props) => (props.left ? `left: ${props.left}` : '')};

  ${(props) => (props.bottom ? `bottom: ${props.bottom}` : '')};
  ${(props) => (props.right ? `right: ${props.right}` : '')};
`;

export default Parallelogram;
