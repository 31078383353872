import styled from 'styled-components';
import { fonts, colors } from 'styles/theme';
import toRem from 'utils/toRem';

interface IText {
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: number;
  color?: string;
  lineHeight?: number;
  letterSpace?: number;
  textTransfrom?: string;
  textDecoration?: string;
  textUnderline?: boolean;
  textAlign?: string;
  marginTop?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
}

const Text = styled.div<IText>`
  display: inline;
  color: ${(props) => props.color};
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => toRem(props.fontSize!)};
  font-weight: ${(props) => props.fontWeight};
  text-transform: ${(props) => props.textTransfrom};
  text-decoration: ${(props) => props.textDecoration};
  letter-spacing: ${(props) => toRem(props.letterSpace!)};
  line-height: ${(props) => props.lineHeight};
  text-align: ${(props) => props.textAlign};
  ${(props) => (props.textUnderline ? 'text-decoration: underline;' : '')};
  margin-bottom: ${(props) => toRem(props.marginBottom!)};
`;

Text.defaultProps = {
  fontFamily: fonts.font1,
  fontSize: 14,
  fontWeight: 400,
  color: colors.color3,
  letterSpace: 1,
  lineHeight: 1,
  textTransfrom: undefined,
  textDecoration: undefined,
  textUnderline: false,
  textAlign: undefined,
};

export default Text;
