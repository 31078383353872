import React from 'react';
import { useHistory } from 'react-router-dom';
import Text from 'components/Text';

import { colors, fonts } from 'styles/theme';
import { StyledTag } from './styles';

interface TagProps {
  title: string;
}

const Tag: React.FC<TagProps> = ({ title }) => {
  const history = useHistory();

  const onTagClickHandler = () => {
    history.push(`/search?tag=${title}`);
  };

  return (
    <StyledTag onClick={onTagClickHandler}>
      <Text color={colors.color2} fontSize={14} fontFamily={fonts.font1}>
        {title}
      </Text>
    </StyledTag>
  );
};

export default Tag;
