import styled from 'styled-components';
import { colors } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

export const StyledFooterContainer = styled.div`
  ${flexGen('flex-start', 'center', 'column')};
  width: 100%;
  min-height: ${toRem(424)};
  background-color: ${colors.color3};
  padding: ${toRem(64)} 8% ${toRem(52)} 8%;
`;

export const StyledFooterNav = styled.div`
  ${flexGen('space-between', 'flex-start', 'row')}
  width: 100%;
  height: 100%;
`;

export const StyledFooterNavList = styled.div`
  ${flexGen('flex-start', 'flex-start', 'column')}
  margin-left: ${toRem(128)};
  white-space: nowrap;
`;

export const StyledFooterIcons = styled.div`
  ${flexGen('flex-end', 'flex-start', 'row')}
  width: 100%;
  height: 100%;
`;

export const StyledFooterCompany = styled.div`
  ${flexGen('flex-start', 'center', 'row')}
  padding-top: ${toRem(12)};
  width: 100%;
`;

export const StyledFooterIconButtonLink = styled.a`
  text-decoration: none;
`;
export const StyledFooterIconButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;

  svg {
    fill: ${colors.color2};
  }
`;

export const StyledExternalTextLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${colors.color7};
  padding-left: 4px;
`;
