import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Text from 'components/Text';
import Parallelogram from 'components/Parallelogram';
import BoxContainer from 'components/BoxContainer';
import { colors, fonts } from 'styles/theme';
import SearchInput from 'components/SearchInput';
import toRem from 'utils/toRem';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import { getCategories } from 'Apis/getCategories';
import { getTopics } from 'Apis/getTopics';
import { getTypeFromCookie } from '../../utils/getTypeFromCookie';
import { IconMap } from './IconMap';
import {
  StyledMainPageWrapper,
  StyledMainPageContainer,
  StyledMainPageHeader,
  StyledMainPageMenus,
  StyledMainPageMenu,
  StyledMainPageIcon,
  StyledMainPageMenuContent,
  StyledMainPageTopics,
  StyledMainPageTopic,
} from './styles';

interface MainProps {}

const Main: React.FC<MainProps> = () => {
  let type = '';
  const [loading, setLoading] = useState<boolean>(true);
  const [adminCategories, setAdminCategories] = useState<any[]>([]);
  const [merchantCategories, setMerchantCategories] = useState<any[]>([]);
  const [publisherCategories, setPublisherCategories] = useState<any[]>([]);
  const [topics, setTopics] = useState<any[]>();

  const history = useHistory();

  useEffect(() => {
    async function fetch() {
      try {
        type = await getTypeFromCookie();
        const categoriesData = await getCategories();
        const adminCategoriesData: any[] = [];
        const merchantCategoriesData: any[] = [];
        const publisherCategoriesData: any[] = [];
        categoriesData.forEach((category: any) => {
          if (category.publisher) {
            publisherCategoriesData.push(category);
          } else if (category.merchant) {
            merchantCategoriesData.push(category);
          } else {
            // default case: admin-only category
            adminCategoriesData.push(category);
          }
        });
        setAdminCategories(adminCategoriesData);
        setMerchantCategories(merchantCategoriesData);
        setPublisherCategories(publisherCategoriesData);

        const topicsData = await getTopics();

        setTopics(topicsData);

        setLoading(false);
      } catch (error: any) {
        // eslint-disable-next-line no-debugger
        debugger;
        if (error.response?.status < 500) {
          history.push('/404');
        } else {
          history.replace('/error');
        }
      }
    }
    fetch();
  }, [history]);

  const renderParallelograms = useCallback(
    () => (
      <>
        <Parallelogram color={colors.color8} width="14%" height="35%" top="10%" left="-10%" />
        <Parallelogram color={colors.color9} width="14%" height="15%" top="35%" left="-7%" />
        <Parallelogram color={colors.color9} width="13%" height="15%" top="80%" left="-7%" />
        <Parallelogram color={colors.color8} width="30%" height="35%" top="83%" left="-10%" />
        <Parallelogram color={colors.color8} width="14%" height="30%" top="-30.5%" right="-5%" />
        <Parallelogram color={colors.color9} width="13%" height="25%" top="-6%" right="-7%" />
        <Parallelogram color={colors.color9} width="30%" height="30%" top="91%" left="60%" />
        <Parallelogram color={colors.color8} width="30%" height="35%" top="77%" left="80%" />
      </>
    ),
    [],
  );

  const renderCategorySection = (categories: any[]) => categories.map((category: { id: string; title: string; slug: string; description: string }) => (
    <BoxContainer key={category.id} to={`/category?id=${category.id}`}>
      <StyledMainPageMenu>
        <StyledMainPageIcon>
          <Icon
            icon={IconMap[category.slug]?.icon}
            width={IconMap[category.slug]?.width}
            height={IconMap[category.slug]?.height}
            viewbox={IconMap[category.slug]?.viewbox}
          />
        </StyledMainPageIcon>
        <StyledMainPageMenuContent>
          <Text fontSize={24} fontFamily={fonts.font3}>
            {category.title}
          </Text>
          <Text fontFamily={fonts.font1} letterSpace={-0.3}>
            {category.description}
          </Text>
        </StyledMainPageMenuContent>
      </StyledMainPageMenu>
    </BoxContainer>
  ));

  const renderCategories = useCallback(
    () => (
      <>
        {adminCategories.length > 0 && (
          <>
            <Text fontSize={32} fontFamily={fonts.font2}>
              Admin
            </Text>
            <StyledMainPageMenus>{renderCategorySection(adminCategories)}</StyledMainPageMenus>
          </>
        )}
        {merchantCategories.length > 0 && (
          <>
            <Text fontSize={32} fontFamily={fonts.font2}>
              Merchant
            </Text>
            <StyledMainPageMenus>{renderCategorySection(merchantCategories)}</StyledMainPageMenus>
          </>
        )}
        {publisherCategories.length > 0 && (
          <>
            {type !== 'publisher' && (
              <Text fontSize={32} fontFamily={fonts.font2}>
                Publisher
              </Text>
            )}
            <StyledMainPageMenus>{renderCategorySection(publisherCategories)}</StyledMainPageMenus>
          </>
        )}
      </>
    ),
    [adminCategories, merchantCategories, publisherCategories],
  );

  const renderTopics = useCallback(() => {
    if (topics) {
      return topics.map((topic: { id: string; name: string; description: string }) => (
        <BoxContainer key={topic.id} to={`/topic?id=${topic.id}`}>
          <StyledMainPageTopic>
            <Text fontSize={24} fontFamily={fonts.font3} lineHeight={1.8}>
              {topic.name}
            </Text>
            <Text
              fontSize={14}
              fontFamily={fonts.font1}
              letterSpace={-0.3}
              lineHeight={1.36}
              textAlign="center"
            >
              {topic.description}
            </Text>
          </StyledMainPageTopic>
        </BoxContainer>
      ));
    }
    return <></>;
  }, [topics]);

  return (
    <StyledMainPageWrapper>
      <StyledMainPageHeader>
        {renderParallelograms()}
        <Text
          fontSize={32}
          fontFamily={fonts.font2}
          color={colors.color2}
          lineHeight={1.48}
          marginBottom={10}
        >
          Fintel Connect Knowledge Base
        </Text>
        <Text
          fontSize={16}
          letterSpace={-0.15}
          fontFamily={fonts.font1}
          color={colors.color2}
          marginBottom={10}
        >
          Get help with Fintel Connect products and services
        </Text>
        <SearchInput placeholder="What can we help you with?" marginTop={16} width={toRem(550)} />
      </StyledMainPageHeader>
      {loading ? (
        <StyledMainPageContainer>
          <Spinner />
        </StyledMainPageContainer>
      ) : (
        <StyledMainPageContainer>
          {renderCategories()}
          <Text fontSize={32} fontFamily={fonts.font2}>
            Topics
          </Text>
          <StyledMainPageTopics>{renderTopics()}</StyledMainPageTopics>
        </StyledMainPageContainer>
      )}
    </StyledMainPageWrapper>
  );
};

export default Main;
