import styled from 'styled-components';
import { fonts } from 'styles/theme';
import flexGen from 'utils/flexGen';
import toRem from 'utils/toRem';

interface IStyledTextLinkWrapper {
  marginRight: number;
  marginLeft: number;
  marginTop: number;
  marginBottom: number;
  clickable: boolean;
}

export const StyledTextLinkWrapper = styled.div<IStyledTextLinkWrapper>`
  ${flexGen('flex-start', 'center', 'row')}
  margin-top: ${(props) => toRem(props.marginTop)};
  margin-bottom: ${(props) => toRem(props.marginBottom)};
  margin-left: ${(props) => toRem(props.marginLeft)};
  margin-right: ${(props) => toRem(props.marginRight)};

  align-items: center;
  align-content: center;

  a {
    text-decoration: none;
  }

  ${(props) => (props.clickable === false
    ? `a {
          pointer-events: none;
          cursor: default;
        }
        font-family: ${fonts.font1} !important;`
    : '')}
`;

export const StyledTextLinkContainer = styled.div`
  ${flexGen('flex-start', 'center', 'row')}
`;
